import { useTranslation } from "react-i18next";
import ListSummary from "../../components/partial/ListSummary";
import ListSummarySlider from "../../components/partial/ListSummarySlider";
import { useEffect, useState } from "react";
import useFetch from "use-http";
import { LANGUAGES } from "../../constants";
import { useNavigate } from "react-router-dom";
import { getExcerpt, removeTags } from "../../helpers";

const HomeInfo = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const category1 = [
    // {
    //   title: t("home.info.news.category.text.1"),
    //   name: "cat1",
    // },
    {
      title: t("home.info.news.category.text.2"),
      name: "BULLION",
    },
    {
      title: t("home.info.news.category.text.3"),
      name: "MKT_COMM",
    },
    {
      title: t("home.info.news.category.text.4"),
      name: "ECON_DATA",
    },
  ];

  const category2 = [
    {
      title: t("home.info.announcements.category.text.1"),
      name: "announcement",
    },
  ];

  const { get, post, response, loading, error } = useFetch(
    process.env.REACT_APP_BACKEND_API_URL
  );

  /**
   * announcement data
   */
  const [announcementData, setAnnouncementData] = useState<any>({
    announcements: [],
  });

  // get announcements function
  const getAnnouncements = async () => {
    // get the language short code
    const languageCode =
      LANGUAGES.find((l) => l.locale === i18n.language)?.code || "tc";

    const results = await get(`/announcements/${languageCode}`);
    if (response.ok) setAnnouncementData(results);
  };

  /**
   * DJ news data
   */
  const [newsData, setNewsData] = useState<any>([]);

  // get news function
  const getNewsList = async () => {
    // get the language short code
    const languageCode = i18n.language === "tc" ? "zh_HK" : "zh_CN";

    const results = await get(`/dj-news-list/${languageCode}`);
    if (response.ok) setNewsData(results);
  };

  /**
   * first fetch
   */
  useEffect(() => {
    getAnnouncements();
    getNewsList();
  }, [i18n.language]);

  return (
    <div className="home-01-intro ">
      {/* desktop and tablet */}
      <div className="ptb--150 ptb_md--75 d-none d-sm-block">
        {/* DJ news */}
        {/* {newsData && (
          <ListSummary
            list={newsData.map((news: any, i: number) => {
              return {
                id: i,
                title: news.title,
                category: news.category,
                date: news.formatDate,
              };
            })}
            filters={category1}
            className=""
            onItemClicked={(id) => navigate(`/${i18n.language}/djnews/${id}`)}
            emptyDataMessage={t("home.info.no-record.news")}
          />
        )} */}

        {/* Announcements */}
        {announcementData.announcements && (
          <ListSummary
            list={announcementData.announcements
              .filter((_: any, i: number) => i < 5)
              .map((a: any, i: number) => {
                const theDate = new Date(a.announcement_date);
                const day = ("0" + theDate.getDate()).slice(-2);
                const month = ("0" + (theDate.getMonth() + 1)).slice(-2);
                const year = theDate.getFullYear();
                const dateString = `${year}-${month}-${day}`;
                return {
                  id: +a.id,
                  title: a.title,
                  category: category2.at(0)?.name,
                  date: dateString,
                };
              })}
            filters={category2}
            readAllText={t("home.info.read-all.announcements")}
            readAllLink="announcement"
            className="mt--0"
            onItemClicked={(id) =>
              navigate(`/${i18n.language}/announcement_details/${id}`)
            }
            emptyDataMessage={t("home.info.no-record.announcements")}
          />
        )}
      </div>
      {/* mobile */}
      {/* <div className="bg-style--12 bg_image d-block d-sm-none pt--60 pb--50">
        <ListSummarySlider
          list={newsData
            .filter((n: any) => n.category === category1.at(0)?.name)
            .map((news: any, i: number) => {
              return {
                id: i,
                title: news.title,
                category: news.category,
                date: news.formatDate,
                excerpt: getExcerpt(removeTags(news.description), 100),
                href: `/${i18n.language}/djnews/${i}`,
              };
            })}
          filters={category1.slice(0, 1)}
          emptyDataMessage={t("home.info.no-record.news")}
        />
      </div> */}
      <div className="bg-style--13 bg_image d-block d-sm-none pt--60 pb--50">
        <ListSummarySlider
          list={announcementData.announcements
            .filter((_: any, i: number) => i < 5)
            .map((a: any, i: number) => {
              const theDate = new Date(a.announcement_date);
              const day = ("0" + theDate.getDate()).slice(-2);
              const month = ("0" + (theDate.getMonth() + 1)).slice(-2);
              const year = theDate.getFullYear();
              const dateString = `${year}-${month}-${day}`;
              return {
                id: +a.id,
                title: a.title,
                category: category2.at(0)?.name,
                date: dateString,
                coverImage: <img src={a.cover_image} alt={a.title} />,
                href: `/${i18n.language}/announcement_details/${a.id}`,
              };
            })}
          filters={category2}
          emptyDataMessage={t("home.info.no-record.announcements")}
        />
      </div>
    </div>
  );
};

export default HomeInfo;
