import { RouteObject } from "react-router-dom";
import Page404 from "./pages/404";
import Page01Home from "./pages/Home";
import Page11AboutHT from "./pages/AboutHT";
import Page12AboutHBL from "./pages/AboutHBL";
import Page13HblAdvantages from "./pages/AboutAdv";
import Page21TradeRules from "./pages/TradeRules";
import Page22TradeCgseCode from "./pages/TradeCgseCode";
import Page23GoldBar from "./pages/GoldBar";
import Page31AccountOpen from "./pages/AccountOpen";
import Page32AccountDemo from "./pages/AccountDemo";
import Page33AccountMarginInOut from "./pages/AccountMarginInOut";
import Page41MT4 from "./pages/PlatformMT4";
import Page42EGold from "./pages/PlatformEGold";
import Page43TradingCentral from "./pages/PlatformTradingCentral";
import Page51IB from "./pages/IB";
import Page52IBSW from "./pages/IBSW";
import Page6Contact from "./pages/Contact";
import Page71Announcements from "./pages/Announcements";
import Page72AnnouncementDetails from "./pages/AnnouncementDetails";
import Page81NewsDetails from "./pages/NewsDetails";
import Page91RiskDisclosures from "./pages/legal/RiskDisclosures";
import Page92PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import Page93ComplaintsProcedure from "./pages/legal/ComplaintsProcedure";
import Page94Disclaimer from "./pages/legal/Disclaimer";
import Page95CookiePolicy from "./pages/legal/CookiePolicy";

export const routes: RouteObject[] = [
  {
    path: "*",
    Component: Page404,
  },
  {
    path: "",
    Component: Page01Home,
  },
  {
    path: "abtht",
    Component: Page11AboutHT,
  },
  {
    path: "aboutHBL",
    Component: Page12AboutHBL,
  },
  {
    path: "hbl_advantages",
    Component: Page13HblAdvantages,
  },
  {
    path: "trade_rules",
    Component: Page21TradeRules,
  },
  {
    path: "cgse_code",
    Component: Page22TradeCgseCode,
  },
  {
    path: "goldbar",
    Component: Page23GoldBar,
  },
  {
    path: "openacc",
    Component: Page31AccountOpen,
  },
  {
    path: "demoacc",
    Component: Page32AccountDemo,
  },
  {
    path: "margin_inout",
    Component: Page33AccountMarginInOut,
  },
  {
    path: "mt4",
    Component: Page41MT4,
  },
  {
    path: "egold",
    Component: Page42EGold,
  },
  {
    path: "trading_central",
    Component: Page43TradingCentral,
  },
  {
    path: "ib",
    Component: Page51IB,
  },
  {
    path: "ibsw",
    Component: Page52IBSW,
  },
  {
    path: "contact",
    Component: Page6Contact,
  },
  {
    path: "announcement",
    Component: Page71Announcements,
  },
  {
    path: "announcement_details",
    Component: Page71Announcements,
  },
  {
    path: "announcement_details/:id",
    Component: Page72AnnouncementDetails,
  },
  {
    path: "djnews",
    Component: Page01Home,
  },
  {
    path: "djnews/:id",
    Component: Page81NewsDetails,
  },
  {
    path: "legal/risk-disclosures",
    Component: Page91RiskDisclosures,
  },
  {
    path: "legal/privacy-policy",
    Component: Page92PrivacyPolicy,
  },
  {
    path: "legal/complaints-procedure",
    Component: Page93ComplaintsProcedure,
  },
  {
    path: "legal/disclaimer",
    Component: Page94Disclaimer,
  },
  {
    path: "legal/cookie-policy",
    Component: Page95CookiePolicy,
  },
];
