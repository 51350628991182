import { ListPresentProps } from "./ListPresentType";

const ListLogo = ({ items, col = "col" }: ListPresentProps) => {
  return (
    <div className="list-logo mt--40 mt_md--75 mt_sm--75 mt_lg--75 plr_lp--100">
      <div className="row row-gap-5 justify-content-center">
        {items
          .filter((_, i) => i != 4)
          .map((item, i) => (
            <div className={col} key={i}>
              <div className="content-wrapper d-flex align-items-center h-100">
                {item.icon && (
                  <div className="icon text-center mb--15 mb_sm--0">
                    {item.icon}
                  </div>
                )}
                <div className="content text-center">
                  {item.title && (
                    <h3 className="title mb--15 mb_sm--5 mb_md--10">
                      {item.title}
                    </h3>
                  )}
                  {item.description && (
                    <p className="description">{item.description}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListLogo;
