import { Trans, useTranslation } from "react-i18next";
import img01 from "../../resources/goldbar/01.jpg";
import img02 from "../../resources/goldbar/02.jpg";
import img03 from "../../resources/about-content/01.jpg";
import { Content } from "../../components/partial/TwoColumnsContent.type";
import TwoColumnsContent from "../../components/partial/TwoColumnsContent";

const GoldBarContent = () => {
  const { t } = useTranslation();

  const components = {
    div: <div className="mb--20" />,
    nl: <br />,
    title: <h3 className="two-columns-content-title" />,
  };

  const data: Content[] = [
    {
      component: (
        <>
          <Trans i18nKey="goldbar.content.title.1.1" components={components} />
          <Trans
            i18nKey="goldbar.content.description.1.1"
            components={components}
          />
          <Trans i18nKey="goldbar.content.title.1.2" components={components} />
          <Trans
            i18nKey="goldbar.content.description.1.2"
            components={components}
          />
        </>
      ),
      image: (
        <img
          src={img01}
          className="img img-br"
          alt={t("goldbar.content.title.1")}
        />
      ),
    },
    {
      title: t("goldbar.content.title.2"),
      description: t("goldbar.content.description.2"),
      image: (
        <img
          src={img02}
          className="img img-br"
          alt={t("goldbar.content.title.2")}
        />
      ),
    },
    {
      title: t("goldbar.content.title.3"),
      component: (
        <>
          <Trans
            i18nKey="goldbar.content.description.3.1"
            components={{
              italic: <i />,
              bold: <strong />,
              red: <span className="theme-color" />,
              atag: <a />,
            }}
          />
          <Trans
            i18nKey="goldbar.content.description.3.2"
            components={{
              italic: <i />,
              bold: <strong />,
              red: <span className="theme-color" />,
              atag: <a />,
            }}
          />
        </>
      ),
      image: (
        <img
          src={img03}
          className="img img-br"
          alt={t("goldbar.content.title.3")}
        />
      ),
    },
  ];

  return <TwoColumnsContent className="goldbar-content" contents={data} />;
};

export default GoldBarContent;
