import flaghk from "../../resources/flags/flag-hk.png";
import flagau from "../../resources/flags/flag-au.png";
import flagjp from "../../resources/flags/flag-jp.png";
import flaguk from "../../resources/flags/flag-uk.png";
import flagv from "../../resources/flags/flag-v.png";
import flagsc from "../../resources/flags/flag-sc.png";
import { useTranslation } from "react-i18next";

const AboutIntroContent = () => {
  const { t } = useTranslation();
  return (
    <div className="wrapper mt--50 mt_sm--75">
      <div className="intro-dark-footer-text mb_sm--40 text-center text-sm-start">
        {t("abtht.about.footer")}
      </div>
      <div className="row row-gap-4 justify-content-center justify-content-md-start">
        {["hk", "au", "uk", "v", "sc"].map((country, i) => {
          let src = "";
          switch (country) {
            case "hk":
              src = flaghk;
              break;
            case "au":
              src = flagau;
              break;
            case "jp":
              src = flagjp;
              break;
            case "uk":
              src = flaguk;
              break;
            case "v":
              src = flagv;
              break;
            case "sc":
              src = flagsc;
              break;
            default:
              src = flaghk;
              break;
          }
          return (
            <div
              className="col-4 col-sm-3 col-md-auto text-center text-md-start"
              key={i}
            >
              <img
                className="flag-img"
                src={src}
                alt={t(`abtht.about.country.${country}`)}
              />
              <div className="intro-dark-country-name">
                {t(`abtht.about.country.${country}`)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutIntroContent;
