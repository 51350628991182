import { useTranslation } from "react-i18next";
import SectionTitle from "../../components/partial/SectionTitle";
import ListPresent from "../../components/partial/ListPresent";
import award1 from "../../resources/awards/award01.png";
import award2 from "../../resources/awards/award02.png";
import award3 from "../../resources/awards/award03.png";
import award4 from "../../resources/awards/award04.png";
import ListLogo from "../../components/partial/ListLogo";

const HomeAwards = () => {
  const { t } = useTranslation();
  const list = Array(2)
    .fill("")
    .map((e, i) => {
      let icon;
      switch (i) {
        case 0:
          icon = <img src={award4} alt={t(`award.list.title.${i + 1}`)} />;
          break;
        case 1:
          icon = <img src={award4} alt={t(`award.list.title.${i + 1}`)} />;
          break;
        case 2:
          icon = <img src={award3} alt={t(`award.list.title.${i + 1}`)} />;
          break;
        default:
          icon = <img src={award4} alt={t(`award.list.title.${1}`)} />;
          break;
      }

      return {
        icon: icon,
        title: t(`award.list.title.${i + 1}`),
        description: t(`award.list.description.${i + 1}`),
      };
    });

  return (
    <div className="home-04-awards ptb--150 pb_sm--75 pb_md--75 pt_sm--60 pt_md--60 bg-style--3 bg_image">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-8">
            <SectionTitle title={t("award.section.header")} />
          </div>
        </div>

        <div className="row justify-content-md-center">
          <ListLogo items={list} col="col-12 col-sm-4 col-xl-3" />
        </div>
      </div>
    </div>
  );
};

export default HomeAwards;
