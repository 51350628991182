export const LANGUAGES = [
  {
    label: "language.tc",
    short: "language.tc.short",
    locale: "tc",
    code: "tc",
  },
  {
    label: "language.sc",
    short: "language.sc.short",
    locale: "sc",
    code: "sc",
  },
];

export const HEADER_MENU = [
  {
    label: "header.menu.1",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "header.menu.1-1",
        href: "/abtht",
        target: "_self",
      },
      {
        label: "header.menu.1-2",
        href: "/aboutHBL",
        target: "_self",
      },
      {
        label: "header.menu.1-3",
        href: "/hbl_advantages",
        target: "_self",
      },
    ],
  },
  {
    label: "header.menu.2",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "header.menu.2-1",
        href: "/trade_rules",
        target: "_self",
      },
      {
        label: "header.menu.2-2",
        href: "/cgse_code",
        target: "_self",
      },
      {
        label: "header.menu.2-4",
        href: "/goldbar",
        target: "_self",
      },
    ],
  },
  {
    label: "header.menu.3",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "header.menu.3-1",
        href: "/openacc",
        target: "_self",
      },
      {
        label: "header.menu.3-2",
        href: "/demoacc",
        target: "_self",
      },
      {
        label: "header.menu.3-3",
        href: "/margin_inout",
        target: "_self",
      },
    ],
  },
  {
    label: "header.menu.4",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "header.menu.4-1",
        href: "/mt4",
        target: "_self",
      },
      {
        label: "header.menu.4-2",
        href: "/egold",
        target: "_self",
      },
      {
        label: "header.menu.4-3",
        href: "/trading_central",
        target: "_self",
      },
    ],
  },
  {
    label: "header.menu.5",
    href: "/ib",
    target: "_self",
  },
  {
    label: "header.menu.6",
    href: "/contact",
    target: "_self",
  },
];

export const FOOTER_MENU = [
  {
    label: "footer.menu.1",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "footer.menu.1-1",
        href: "/abtht",
        target: "_self",
      },
      {
        label: "footer.menu.1-2",
        href: "/aboutHBL",
        target: "_self",
      },
      {
        label: "footer.menu.1-3",
        href: "/hbl_advantages",
        target: "_self",
      },
    ],
  },
  {
    label: "footer.menu.2",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "footer.menu.2-1",
        href: "/trade_rules",
        target: "_self",
      },
      {
        label: "footer.menu.2-2",
        href: "/demoacc",
        target: "_self",
      },
      {
        label: "footer.menu.2-4",
        href: "/goldbar",
        target: "_self",
      },
    ],
  },
  {
    label: "footer.menu.3",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "footer.menu.3-1",
        href: "/openacc",
        target: "_self",
      },
      {
        label: "footer.menu.3-2",
        href: "/demoacc",
        target: "_self",
      },
      {
        label: "footer.menu.3-3",
        href: "/margin_inout",
        target: "_self",
      },
    ],
  },
  {
    label: "footer.menu.4",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "footer.menu.4-1",
        href: "/mt4",
        target: "_self",
      },
      {
        label: "footer.menu.4-2",
        href: "/egold",
        target: "_self",
      },
      {
        label: "footer.menu.4-3",
        href: "/trading_central",
        target: "_self",
      },
    ],
  },
  {
    label: "footer.menu.5",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "footer.menu.5-1",
        href: "/ib",
        target: "_self",
      },
      // {
      //   label: "footer.menu.5-2",
      //   href: "/ibsw",
      //   target: "_self",
      // },
    ],
  },
  {
    label: "footer.menu.6",
    href: "#",
    target: "_self",
    sub: [
      {
        label: "footer.menu.6-1",
        href: "/contact",
        target: "_self",
      },
    ],
  },
];

export const FOOTER_NAV = [
  {
    label: "footer.nav.1",
    href: "/legal/risk-disclosures",
    target: "_self",
  },
  {
    label: "footer.nav.2",
    href: "/legal/privacy-policy",
    target: "_self",
  },
  {
    label: "footer.nav.3",
    href: "/legal/complaints-procedure",
    target: "_self",
  },
  {
    label: "footer.nav.4",
    href: "/legal/disclaimer",
    target: "_self",
  },
  {
    label: "footer.nav.5",
    href: "/legal/cookie-policy",
    target: "_self",
  },
];

export const LEAD_CREATE_API_URL =
  "https://rinex-portal.hantecgroup.com/api/mks/lead/create";
