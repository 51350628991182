import classnames from "classnames";
import { TwoColumnsContentProps } from "./TwoColumnsContent.type";

const TwoColumnsContent = (props: TwoColumnsContentProps) => {
  const classNames = classnames(
    "two-columns-content ptb--150 pt_sm--0 pb_sm--0 pt_md--0 pb_md--0 pt_lg--60 pb_lg--75",
    props.className!
  );
  return (
    <div className={classNames}>
      <div className="container-lg gx-0 gx-lg-4">
        {props.contents.map((c, i) => {
          /* some class names definition */
          const classNameRow = classnames("row align-items-center", {
            "mb--150 mb_sm--0 mb_md--0 mb_lg--75":
              i + 1 !== props.contents.length,
          });
          const isOdd = ((i + 1) & 1) === 1;
          const classNameContent = classnames(
            "two-columns-content-container",
            "text-center text-lg-start",
            "plr_sm--20 plr_md--20 pt_sm--60 pb_sm--75 pt_md--60 pb_md--75",
            {
              "pr--120 pr_lg--70": isOdd,
              "pl--120 pl_lg--70": !isOdd,
            }
          );

          // the text content
          const textContent = (
            <div className={classNameContent}>
              {c.title && (
                <h3 className="two-columns-content-title">{c.title}</h3>
              )}
              {c.description && (
                <div className="two-columns-content-desc">{c.description}</div>
              )}
              {c.component && (
                <div className="two-columns-content-desc">{c.component}</div>
              )}
            </div>
          );

          return (
            <div className={classNameRow} key={i}>
              {/* mobile and tablet only */}
              <div className="col-12 col-lg-6 d-block d-lg-none">{c.image}</div>

              {!isOdd && (
                <div className="col-12 col-lg-6 d-none d-lg-block">
                  {c.image}
                </div>
              )}
              {/* the content */}
              <div className="col-12 col-lg-6">{textContent}</div>
              {/* the content */}
              {isOdd && (
                <div className="col-12 col-lg-6 d-none d-lg-block">
                  {c.image}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TwoColumnsContent;
